import React, { useEffect, useState } from "react";
import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import UserLandingPage from "./pages/UserLandingPage";
import FormDataInputPage from "./pages/FormDataInputPage";
import { initializeAmplitude } from "./components/Amplitude";
import DonePage from "./pages/DonePage";

function App() {
  const [amplitudeInitialized, setAmplitudeInitialized] = useState(false);

  useEffect(() => {
    const initializeAndSetUserId = async () => {
      try {
        await initializeAmplitude();
        setAmplitudeInitialized(true);
      } catch (error) {
        console.error("Error in initialization:", error);
      }
    };

    initializeAndSetUserId();
  }, []);

  return (
    <div className={amplitudeInitialized ? "" : "flex justify-center"}>
      {amplitudeInitialized ? (
        <Router>
          <Routes>
            <Route path="/" element={<UserLandingPage />} />
            <Route path="/form" element={<FormDataInputPage />} />
            <Route path="/done" element={<DonePage />} />
          </Routes>
        </Router>
      ) : (
        "Logding"
      )}
    </div>
  );
}

export default App;
