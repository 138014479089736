import * as amplitude from "@amplitude/analytics-browser";

// const amplitudeApiKey = process.env.REACT_APP_AMP_PROD_KEY
// const devAmplitudeApiKey = process.env.REACT_APP_AMP_DEV_KEY

const ampKey =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_AMP_DEV_KEY
    : process.env.REACT_APP_AMP_PROD_KEY;

export const initializeAmplitude = async () => {
  return amplitude.init(ampKey!, {
    defaultTracking: {
      attribution: true,
      pageViews: false,
      sessions: true,
      formInteractions: false,
      fileDownloads: false,
    },
  });
};

export const AmplitudeResetUserId = async () => {
  try {
    amplitude.reset();
  } catch (error) {
    console.error("Amplitude 초기화 중 오류 발생:", error);
  }
};

export const sendEventToAmplitude = async (
  eventName: string,
  properties: any
) => {
  try {
    if (process.env.NODE_ENV === "development") {
      console.log(`${eventName}: ${JSON.stringify(properties || {})}`);
    }
    amplitude.track(eventName, properties || {});
  } catch (error) {
    console.error("Amplitude 초기화 중 오류 발생:", error);
  }
};
