import { useEffect } from "react";
import { Link } from "react-router-dom";
import { sendEventToAmplitude } from "../../components/Amplitude";
import Introduction from "../../components/LandingPageComponents/Introduction";
import IntroTile from "../../components/LandingPageComponents/IntroTile";
import Title from "../../components/LandingPageComponents/Title";
import Layout from "../../components/Layout";

const UserLandingPage = () => {
  useEffect(() => {
    sendEventToAmplitude("view landing page", "");
  }, []);

  const handleAmplitudeData = () => {
    sendEventToAmplitude("click start in landing page", "");
  };

  return (
    <Layout
      item={
        <div className="">
          <div className="ml-6 flex flex-col items-start flex-1 pb-[150px]">
            <div className="mt-9 mb-2">
              <Title text="옷장 정리 도우미, 클로클" />
            </div>
            <IntroTile text="안 입는 브랜드 의류 판매하고" />
            <IntroTile text="치킨 값을 벌 수 있어요" />
            <Introduction
              section="하나"
              info="브랜드 정보를 입력하고, 수거 요청하기"
              imgSrc="./images/basket.png"
              imgAlt="상자"
              firstDetails="신뢰할 수 있는"
              secondDetails="중고 플랫폼 판매자를 매칭해 드려요"
            />
            <Introduction
              section="둘"
              info="매칭되면 수거 장소에 상품 두기"
              imgSrc="./images/box.png"
              imgAlt="상자"
              firstDetails="소중한 상품은"
              secondDetails="플랫폼이 안전하게 수거해요"
            />
            <Introduction
              section="셋"
              info="판매금의 70%를 정산 받기"
              imgSrc="./images/moneybag.png"
              imgAlt="상자"
              firstDetails="판매되지 않은 상품은"
              secondDetails="돌려 받거나, 폐기 요청할 수 있어요"
            />
          </div>

          <div className="w-full px-3 fixed left-0 md:left-0 bottom-0 mb-5">
            <Link to="/form" className="" onClick={handleAmplitudeData}>
              <button className="p-[14px] w-full rounded-[12px] bg-yellow-500 bg-defaultyellow-500 text-white text-base font-bold">
                시작하기
              </button>
            </Link>
          </div>
        </div>
      }
    ></Layout>
  );
};

export default UserLandingPage;
