import { ReactElement } from "react";

const Layout = ({ item }: any) => {
  return (
    <div className="text-center mx-auto max-w-[650px] bg-[#121212] min-h-screen relative">
      {item}
    </div>
  );
};

export default Layout;
