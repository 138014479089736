import React from "react";

type IntroductionType = {
  section: string;
  info: string;
  imgSrc: string;
  imgAlt: string;
  firstDetails: string;
  secondDetails: string;
};

const Introduction = ({
  section,
  info,
  imgSrc,
  imgAlt,
  firstDetails,
  secondDetails,
}: IntroductionType) => {
  return (
    <div className="flex flex-col items-start mt-14">
      <h1 className="text-yellow-500 font-bold text-sm mb-1">{section}</h1>
      <h2 className="text-white font-bold md:text-lg text-lg">{info}</h2>
      <div className="mt-5 flex">
        <img className="w-[40px] h-[40px]" src={imgSrc} alt={imgAlt} />
        <div className="ml-5 text-left">
          <p className="text-gray-500 mb-1 text-sm font-medium">
            {firstDetails}
          </p>
          <p className="text-gray-400 font-bold text-sm">{secondDetails}</p>
        </div>
      </div>
    </div>
  );
};

export default Introduction;
