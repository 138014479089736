import React from "react";
import { TextType } from "./Title";

const IntroTile = ({ text }: TextType) => {
  return (
    <div className="text-gray-50 md:text-2xl text-3xl font-bold">{text}</div>
  );
};

export default IntroTile;
