import React from "react";

export type TextType = {
  text: string;
};

const Title = ({ text }: TextType) => {
  return <div className="md:text-sm text-yellow-500 font-medium">{text}</div>;
};

export default Title;
