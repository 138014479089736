import { useEffect } from "react";
import Layout from "../../components/Layout";
import { Link } from "react-router-dom";
import IntroTile from "../../components/LandingPageComponents/IntroTile";
import { sendEventToAmplitude } from "../../components/Amplitude";

const DonePage = () => {
  useEffect(() => {
    sendEventToAmplitude("view application completed page", "");
  }, []);
  return (
    <Layout
      item={
        <div className="px-5 flex items-center justify-center w-full h-screen">
          <div className="grid place-items-center">
            <img className="w-[66px]" src="/images/bell.png" />
            <div className="text-xl leading-7 font-bold text-yellow-500  mt-6">
              오픈 알림을 신청했어요
            </div>
            <div className="text-xl leading-7 font-bold text-white mt-2">
              빨리 준비해서 보여 드릴게요{" "}
            </div>
          </div>
        </div>
      }
    ></Layout>
  );
};

export default DonePage;
