import { useEffect, useState } from "react";
import Layout from "../../components/Layout";
import { Link } from "react-router-dom";
import { sendEventToAmplitude } from "../../components/Amplitude";

const FormDataInputPage = () => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const phoneNumberController = (data: string) => {
    setPhoneNumber(data);
  };
  useEffect(() => {
    sendEventToAmplitude("view notification page", "");
  }, []);
  const handleAmplitudeData = () => {
    sendEventToAmplitude("click to request notification", {
      "phone number": phoneNumber,
    });
  };
  return (
    <Layout
      item={
        <div className="px-5">
          <div className="py-9">
            <div className="flex gap-[56px] flex-col">
              <div className="flex flex-col text-left gap-[4px]">
                <div>
                  <div className="text-gray-400 text-base font-bold">
                    아직 준비중인 서비스예요
                  </div>
                </div>
                <div className="text-white text-xl font-bold">
                  오픈되면 알려 드릴까요?
                </div>
              </div>
              <div>
                <form action="">
                  <div className="flex h-[46px] items-center px-[12px] py-0 relative border-b-2 [border-bottom-style:solid] border-yellow-500">
                    <input
                      type="tel"
                      placeholder="전화번호 입력"
                      onChange={(e) => setPhoneNumber(e.target.value)}
                      className="font-medium outline-0 bg-transparent text-white relative w-fit font-text-xl-leading-7-font-medium font-[number:var(--text-xl-leading-7-font-medium-font-weight)] text-defaultgray-400 text-[length:var(--text-xl-leading-7-font-medium-font-size)] tracking-[var(--text-xl-leading-7-font-medium-letter-spacing)] leading-[var(--text-xl-leading-7-font-medium-line-height)] whitespace-nowrap [font-style:var(--text-xl-leading-7-font-medium-font-style)]"
                    ></input>
                  </div>
                  <p className="text-xs font-medium text-gray-300 text-left mt-2.5">
                    암호화 해서 서버에 안전하게 저장할게요
                  </p>
                </form>
              </div>
            </div>
          </div>
          <div className="w-full px-3 fixed left-0 md:left-0 bottom-0 mb-5 ">
            <Link to="/done" className="">
              <button
                onClick={(e) => {
                  if (phoneNumber.length) {
                    handleAmplitudeData();
                  } else {
                    alert("전화번호를 입력해주세요");
                    e.preventDefault();
                  }
                }}
                className="p-[14px] w-full rounded-[12px] bg-yellow-500 w-100% bg-defaultyellow-500 text-white text-base font-bold"
              >
                오픈하면 알람 받기
              </button>
            </Link>
          </div>
        </div>
      }
    ></Layout>
  );
};

export default FormDataInputPage;
